import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Slider from "react-slick";
import { languageMerge } from "../helpers/languageMerge";
import Link from "../helpers/Link";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { slug2path } from "../helpers/slug2path";
import ScrollScaleHero from "./ScrollScaleHero";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Div100vh from "react-div-100vh";

const Wrapper = styled(Div100vh)`
  & .slick-arrow.slick-next {
    transform: translateX(-50px);
    z-index: 100;
  }
  & .slick-arrow.slick-prev {
    transform: translateX(50px);
    z-index: 100;
  }

  & .slick-arrow.slick-prev,
  & .slick-arrow.slick-next {
    top: auto;
    bottom: 60px;
  }

  /* height: 100vh; */
  overflow: hidden;
  width: 100%;
  /* background: rgba(0, 0, 0, 0.1); */
  position: relative;

  &:before {
    content: "";
    position: absolute;
    /* scroll-snap-align: center; */
  }
  &:after {
    box-shadow: inset 0px 200px 180px -180px rgb(0 0 0 / 25%);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    pointer-events: none;
  }

  .slick-dots {
    bottom: 20px;
    position: absolute;

    & button:before {
      color: #fff !important;
      font-size: 12px;
    }
  }

  .title-slides {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    pointer-events: none;
    z-index: 100;

    @media screen and (max-width: 800px) {
      bottom: 60px;
    }
  }

  & picture > img {
    filter: brightness(0.9);
  }
`;

const Slide = styled(Div100vh)`
  display: grid !important;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  & > a {
    display: grid !important;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

const TitleSlide = styled.div`
  display: flex !important;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 10vh;
  color: #fff;
  & .slug {
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: 800;
  }
  & .title {
    font-size: 2rem;
    font-weight: 800;
  }
  & .location {
    font-size: 2rem;
    font-weight: 400;
  }
  & .date {
    font-size: 1rem;
    text-transform: uppercase;
  }
  /* 
  & > a {
    display: grid !important;
    width: 100%;
    height: 100vh;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  } */

  @media screen and (max-width: 850px) {
    & .slug {
      font-size: 3.2rem;
    }
    & .title {
      font-size: 1.7rem;
    }
    & .location {
      font-size: 1.7rem;
    }
    & .date {
      font-size: 0.8rem;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 3vh;

    & .slug {
      font-size: 3rem;
      text-transform: uppercase;
      font-weight: 800;
    }
    & .title {
      font-size: 1.5rem;
      font-weight: 800;
    }
    & .location {
      font-size: 1.5rem;
      font-weight: 400;
    }
    & .date {
      font-size: 0.8rem;
      text-transform: uppercase;
    }
  }

  @media screen and (max-width: 500px) {
    & .slug {
      font-size: 2rem;
    }
    & .title {
      font-size: 1.2rem;
    }
    & .location {
      font-size: 1.2rem;
    }
    & .date {
      font-size: 0.8rem;
    }
  }
`;

let dragging = false;

const HeroSlider = () => {
  const { i18n } = useTranslation();
  const titleSliderRef = React.useRef();
  const sliderRef = React.useRef();
  const initialSwipeTimeout = React.useRef();
  const [titleSliderRefCaptured, setTitleSliderRefCaptured] = React.useState(false);

  React.useEffect(() => {
    setTitleSliderRefCaptured(true);

    if (sliderRef) {
      initialSwipeTimeout.current = setTimeout(() => {
        sliderRef.current.slickGoTo(1);
      }, 2500);
    }
    return () => {
      initialSwipeTimeout.current && clearTimeout(initialSwipeTimeout.current);
    };
  }, [titleSliderRef, sliderRef]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    easing: "ease",
    autoplay: true,
    // autoplay: false,
    autoplaySpeed: 6000,
  };
  return (
    <StaticQuery
      query={graphql`
        query HeroSlider {
          slugs: allMarkdownRemark(filter: { frontmatter: { heroOrder: { gt: 0 } } }) {
            edges {
              node {
                fields {
                  slug
                }
              }
            }
          }
          allMarkdownRemark {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  heroOrder
                  title
                  location
                  date
                  tags
                  slug
                  featuredimage {
                    base
                    url: publicURL
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const slugs = [
          ...new Set([
            ...data.slugs.edges.flatMap((edge) => {
              const slug = edge.node.fields.slug;
              return [slug, slug.replace(/\.sk\/$/, `.${i18n.language}/`)];
            }),
          ]),
        ];
        console.log({ slugs });

        data = languageMerge({ allMarkdownRemark: data.allMarkdownRemark }, i18n.language);
        const edges = data.allMarkdownRemark.edges
          .filter((edge) => {
            return slugs.indexOf(edge.node.fields.slug) >= 0 && edge.node.frontmatter.heroOrder > 0;
          })
          .sort((edgeA, edgeB) => edgeA.node.frontmatter.heroOrder - edgeB.node.frontmatter.heroOrder);

        // console.log(
        //   edges.map(({ node }) => node.frontmatter)
        // );
        return (
          <>
            <Helmet>
              {" "}
              <link
                rel="stylesheet"
                type="text/css"
                charset="UTF-8"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
              />{" "}
              <link
                rel="stylesheet"
                type="text/css"
                href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
              />{" "}
            </Helmet>
            <div className="snap-section">
              <Wrapper>
                <ScrollScaleHero>
                  <div className="title-slides">
                    <Slider {...settings} autoplay={false} dots={false} arrows={false} ref={titleSliderRef} speed={800}>
                      {edges.map(({ node }, i) => {
                        const monthsSK = [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "Máj",
                          "Jún",
                          "Júl",
                          "Aug",
                          "Sep",
                          "Okt",
                          "Nov",
                          "Dec",
                        ];

                        const date = new Date(node.frontmatter.date);
                        const year = date.getFullYear();
                        const month = monthsSK[date.getMonth()];

                        return (
                          <TitleSlide key={i}>
                            <h2 className="slug">{node.frontmatter.slug}</h2>
                            <h1 className="title">
                              {node.frontmatter.title}, <span className="location">{node.frontmatter.location}</span>
                            </h1>

                            <h2 className="date">
                              {month} {year}
                            </h2>
                          </TitleSlide>
                        );
                      })}
                    </Slider>
                  </div>
                  {titleSliderRefCaptured && (
                    <Slider
                      {...settings}
                      ref={sliderRef}
                      asNavFor={titleSliderRef.current}
                      onSwipe={() => initialSwipeTimeout.current && clearTimeout(initialSwipeTimeout.current)}
                      pauseOnHover={false}
                      pauseOnDotsHover={true}
                      pauseOnFocus={false}
                      arrows={true}
                    >
                      {edges.map(({ node }, i) => (
                        <Slide key={i}>
                          <Link to={slug2path(node.fields.slug)} onClick={(e) => dragging && e.preventDefault()}>
                            <GatsbyImage
                              image={getImage(node.frontmatter.featuredimage)}
                              alt={""}
                              objectFit="cover"
                              objectPosition="50% 50%"
                              loading={"eager"}
                              onMouseDown={() => {
                                dragging = false;
                              }}
                              onMouseMove={() => {
                                dragging = true;
                              }}
                            />
                          </Link>
                        </Slide>
                      ))}
                    </Slider>
                  )}
                </ScrollScaleHero>
              </Wrapper>
            </div>
          </>
        );
      }}
    />
  );
};

export default HeroSlider;
