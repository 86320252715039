import React from "react";
// import styled from "styled-components";
import { graphql } from "gatsby";
import HeroSlider from "../components/HeroSlider";

import Layout from "../components/Layout";
// import Bio from "../components/sections/Bio";
// import Team from "../components/sections/Team";
// import Contact from "../components/sections/Contact";
// import Footer from "../components/sections/Footer";

// import { useTranslation } from "gatsby-plugin-react-i18next";

// const YearBlock = styled.div`
//   min-height: 60vh;
//   padding: 5vh 5vw;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;

//   & h2 {
//     font-size: 50px;
//     color: #a6a796;
//     text-align: center;
//     font-weight: 500;
//     line-height: 1em;

//     & span {
//       font-size: 150px;
//       display: block;
//       color: #52553f;
//       font-weight: 700;
//       line-height: 1em;
//     }
//   }
// `;

const Index = (data) => {
  // const { t } = useTranslation();
  return (
    <>
      <Layout>
        <HeroSlider />
        {/* <Bio t={t} />
        <Team />
        <Contact /> */}
        {/* <YearBlock>
          <h2>
            <span>25</span> rokov tvorivej práce
            <br /> a skúseností
          </h2>
        </YearBlock> */}
        {/* <Footer /> */}
      </Layout>
    </>
  );
};

export default Index;

export const pageQuery = graphql`
  query HomePage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
